<template>
	<div class="phi-thread-feed">

		<div>
			<div class="empty" v-show="collection.isEmpty">
				<p>{{ $t('notice.thereIsNothingHere') }}</p>
			</div>

			<section class="phi-card phi-z-0">
                <div
					v-for="thread in threads"
					:key="thread.id"
				>
                    <thread-li
                        :value="thread"
                        class="thread-listitem"
                        :selected="collection.hasTag(thread, 'selected')"
                        @click="clickThread(thread)"
                        @toggle="toggleThread(thread)"
                    ></thread-li>
                </div>
			</section>

			<mu-infinite-scroll v-if="scrollContainer && !collection.isEmpty" :scroller="scrollContainer" :loading="isLoading" @load="fetchNext()" :loading-text="$t('notice.loading')" />
		</div>

		<mu-snackbar v-if="toastIsShown && lastAction" :message="lastAction.text" :action="$t('action.undo')" @action-click="undo()" @close="toastIsShown = false" />
	</div>
</template>

<script>
import PhiThreadListItem from '@/components/Phi/Thread/ListItem.vue';
import PhiThreadFilters from '@/components/Phi/Thread/Filters.vue';

export default {
	components: {
		'thread-li': PhiThreadListItem,
		'thread-filters': PhiThreadFilters
	},

	props: {
        scrollContainer: {},

		personId: {
			default() {
				return this.$store.state.user.id;
			}
		},

		folder: {
			required: false,
			default: "inbox"
		},

		type: {
			required: false,
			default: null
		},

		filters: {
            type: Object,
            required: false,
            default: null
		}
	},

	data() {
		return {
			collection: this.$store.state.api.collection(`/people/${this.personId}/threads2/feed`, {limit: 100}),
			lastAction: null,
			toastIsShown: false,
			isLoading: false
		}
	},

	computed: {
		threads() {
			return this.collection.items;
		},

		parsedFilters() {
			let current = {
				folder: this.folder,
				type: this.type
			};

			return this.filters ? Object.assign(current, this.filters) : current;
		}
	},

    mounted() {
        this.reload();
    },

    watch: {
		folder() {
			this.reload();
		},

		type() {
			this.reload();
		},

        filters: {
            deep: true,
            handler() {
                this.reload();
            }
        }
    },

	methods: {
		reload() {
			return this.collection.fetch(this.parsedFilters);
		},

		fetchNext() {
			if (!this.collection.hasNext) {
				return;
			}

			this.isLoading = true;
			this.collection.next()
				.finally(() => this.isLoading = false);
		},

		clickThread(thread) {
			this.$emit("click", thread);
		},

        toggleThread(thread) {
            this.collection.toggleTag(thread, 'selected');
            this.emitSelection();
        },

        emitSelection() {
            this.$emit("select", {
                selection: this.collection.tagged("selected")
            });
        },

		select(query) {
			if (query == "all") {
                this.collection.tag(this.collection.items, "selected");
                this.emitSelection();
				return;
			}

			this.collection.untag(this.collection.items, "selected");
			if (query == "none") {
                this.emitSelection();
				return;
			}

			this.collection.items.forEach(thread => {
				switch (query) {
					case "read":
						if (thread.posts.unread == 0) {
							this.collection.tag(thread, "selected");
						}
					break;

					case "unread":
						if (thread.posts.unread > 0) {
							this.collection.tag(thread, "selected");
						}
					break;

					default:
						if (query == thread.id) {
							this.collection.tag(thread, "selected");
						}
					break;
				}
            });

            this.emitSelection();
		},

		move(target) {
			var selection = this.collection.tagged("selected");
			if (!selection.length) {
				return;
			}

			var threadIds = selection.map(thread => thread.id);

			this.$store.state.api
				.post(`/people/${this.personId}/threads/${target}`, threadIds)
				.then(() => {
					if (target != "read" && target != "unread") {
						this.collection.hide(selection);
					} else {
                        this.collection.untag(selection, "selected");

						selection.forEach(thread => {
							thread.posts.unread = target == "unread" ? thread.posts.total : 0;
						});
					}

					this.reload();

					this.lastAction = {
						target,
						selection,
						threadIds,
						text: this.redact(target, threadIds.length)
                    };

                    this.toastIsShown = true;
                    setTimeout(() => this.toastIsShown = false, 4000);

                    this.emitSelection();
				});
		},

		undo() {
			if (!this.lastAction) {
				return;
			}

			var target;
			switch (this.lastAction.target) {
				case "read":
					target = "unread";
					break;

				case "unread":
					target = "read";
					break;

				case "feed":
					target = "archive";
					break;

				case "archive":
				case "trash":
					target = "feed";
					break;
			}

			var targetUrl = `/people/${this.personId}/threads/${target}`;

			return this.$store.state.api.post(targetUrl, this.lastAction.threadIds)
				.then(response => {

					if (target != "read" && target != "unread") {
						this.collection.show(this.lastAction.selection);
					} else {
						this.collection.tag(this.lastAction.selection, "selected");
						this.lastAction.selection.forEach(thread => {
							thread.stub.readDate = target == "unread" ? null : 123;
						});
					}

					this.lastAction = null;

					return response;
				});
		},

		redact(target, count) {
			var plural    = count > 1;
			var redaction = count + ' ';
			switch (target) {
				case 'feed':
					redaction = redaction + (plural ? 'restaurados' : 'restaurado');
				break;
				case 'archive':
					redaction = redaction + (plural ? 'archivados' : 'archivado');
				break;
				case 'trash':
					redaction = redaction + (plural ? 'eliminados' : 'eliminado');
				break;
				case 'read':
					redaction = redaction + (plural ? 'marcados como leído' : 'marcado como leído');
				break;
				case 'unread':
					redaction = redaction + (plural ? 'marcados como no leído' : 'marcado como no leído');
				break;
			}

			return redaction;
		}
	}
}
</script>